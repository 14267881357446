import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import {
  makeStyles,
  styled,
} from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import globalStyles from 'src/styles/globalStyles'

import {
  Container as MuiContainer,
  Hidden,
  Typography,
  Grid as MuiGrid,
  Box,
  AppBar,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  IconButton,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import PrintIcon from '@material-ui/icons/Print'
import DescriptionIcon from '@material-ui/icons/Description'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Layout from 'src/components/layout'
import { SectionHeader } from 'src/components/headers'
import { parseAlt } from 'src/helpers'

const Container = styled(MuiContainer)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles(theme => ({
  ...globalStyles,
  map: {
    border: 'none',
    borderRadius: '4px'
  },
  addressIcon: {
    paddingRight: theme.spacing(1),
    color: '#3871c2',
    paddingTop: '2px'
  },
  addressLine: {
    display: 'flex',
    '& a': {
      color: '#3871c2'
    },
    '& span': {
      display: 'block',
      lineHeight: '28px'
    },
    '& p': {
      lineHeight: '28px',
      margin: '0px'
    },
    '& p:nth-of-type(2)': {
      paddingBottom: theme.spacing(1)
    }
  },
  addressContent: {
    position: 'relative',
    flexGrow: '1'
  },
  addressLabel: {
    fontSize: '0.8rem',
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: '20px',
    position: 'absolute',
    top: '-20px',
    left: '0px',
    userSelect: 'none'
  },
  person: {
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: theme.spacing(3),
    borderRadius: '10px',
    '& span': {
      display: 'inline-block'
    }
  },
  personImg: {
    borderRadius: '50%'
  },
  personName: {
    fontWeight: 'bold'
  },
  personPosition: {
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  department: {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      paddingLeft: '0px'
    }
  },
  departmentDetails: {
    padding: '0px'
  },
  departmentHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  }
}))


const Contact = ({ data, pageContext }) => {
  const Contact = data.contentfulContact
  const classes = useStyles()
  const theme = useTheme()
  const bptMd = useMediaQuery(theme.breakpoints.up('md'))

  const [openedDepartment, setOpenedDepartment] = useState(false)
  const handleDepartmentOpen = (id) => (event, newExpanded) => {
    setOpenedDepartment(newExpanded ? id : false)
  }

  return (
    <Layout pageContext={pageContext}>
      <Container pt={8}>
        <SectionHeader>{Contact.name}</SectionHeader>

        <Grid container pb={4} pt={2} spacing={3}>
          <Grid item xs={12} md={4} pt={1}>
            <Box className={classes.addressLine} pt={3}>
              <LocationOnIcon className={classes.addressIcon} />
              <div className={classes.addressContent}>
                <span className={classes.addressLabel}>{Contact.addressLabel}</span>
                <span>{Contact.address}</span>
              </div>
            </Box>
            <Box className={classes.addressLine} pt={3}>
              <PhoneIcon className={classes.addressIcon} />
              <div className={classes.addressContent}>
                <span className={classes.addressLabel}>{Contact.phoneLabel}</span>
                <span><a href={`tel:${Contact.phone}`}>{Contact.phone}</a></span>
              </div>
            </Box>
            <Box className={classes.addressLine} pt={3}>
              <EmailIcon className={classes.addressIcon} />
              <div className={classes.addressContent}>
                <span className={classes.addressLabel}>{Contact.emailLabel}</span>
                <span><a href={`mailto:${Contact.email}`} className={classes.link}>{Contact.email}</a></span>
              </div>
            </Box>
            <Box className={classes.addressLine} pt={3}>
              <PrintIcon className={classes.addressIcon} />
              <div className={classes.addressContent}>
                <span className={classes.addressLabel}>{Contact.faxLabel}</span>
                <span>{Contact.fax}</span>
              </div>
            </Box>
            <Box className={classes.addressLine} pt={3}>
              <AccountBalanceWalletIcon className={classes.addressIcon} />
              <div className={classes.addressContent}>
                <span className={classes.addressLabel}>{Contact.shareCapitalLabel}</span>
                <span>{Contact.shareCapital}</span>
              </div>
            </Box>
            <Box className={classes.addressLine} pt={3}>
              <DescriptionIcon className={classes.addressIcon} />
              <div className={classes.addressContent}>
                {Contact.register ? (
                  documentToReactComponents(JSON.parse(Contact.register.raw))
                ) : ''}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <iframe className={classes.map} src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCVLPwml1ZouVg0TiuZCcrlSjgdJtCJbpw&q=Bama+Logistics+Kozłów+53B,+39-200+Kozłów&zoom=12&language=${pageContext.lang}`} width="100%" height="430px" loading="lazy"></iframe>
          </Grid>
        </Grid>

        <Box pb={8}>
          {Contact.departments.map(department => (
            <Accordion className={classes.department} expanded={openedDepartment === department.id} onChange={handleDepartmentOpen(department.id)} key={department.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <SectionHeader className={classes.departmentHeader} h3>{department.title}</SectionHeader>
              </AccordionSummary>
              <AccordionDetails className={classes.departmentDetails}>
                <Grid container spacing={3}>
                  {department.people.map(person => (
                    <Grid item xs={12} md={6} key={person.id}>
                      <Box className={classes.person} display="flex" flexDirection={bptMd ? 'row' : 'column'}>
                        <Box width={bptMd ? '160px' : '200px'} pb={bptMd ? 0 : 3} mx="auto">
                          <GatsbyImage className={classes.personImg} image={getImage(person.picture)} alt={parseAlt(person.picture, pageContext)} />
                        </Box>
                        <Box flexGrow={1} pl={bptMd ? 3 : 0} textAlign={bptMd ? 'left' : 'center'}>
                          <Box pb={bptMd ? 0 : 2}>
                            <Typography className={classes.personName} variant="h6" component="h2">
                              {person.name}
                            </Typography>
                            <span className={classes.personPosition}>{person.position}</span>
                          </Box>
                          <Box mx="auto" display="inline-block">
                          {person.phone ? (
                            <Box className={classes.addressLine} pt={1}>
                              <PhoneIcon className={classes.addressIcon} />
                              <span>{person.phone}</span>
                            </Box>
                          ) : ''}
                          {/*person.cellPhone ? (
                            <Box className={classes.addressLine} pt={1}>
                              <PhoneAndroidIcon className={classes.addressIcon} />
                              <span>{person.cellPhone}</span>
                            </Box>
                          ) : ''*/}
                          <Box className={classes.addressLine} pt={1}>
                            <EmailIcon className={classes.addressIcon} />
                            <span>
                              <a href={`mailto:${person.email}`} className={classes.link}>{person.email}</a>
                            </span>
                          </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    contentfulContact(id: { eq: $id }) {
      name
      addressLabel
      address
      phoneLabel
      phone
      emailLabel
      email
      faxLabel
      fax
      shareCapitalLabel
      shareCapital
      register {
        raw
      }
      departments {
        id
        title
        people {
          id
          name
          picture {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          position
          phone
          email
        }
      }
    }
  }
`
